/* eslint-disable camelcase */
import _ from 'lodash'

import { LocalizationKey } from '../lib'
import { __ } from './__'
import { count as countFn } from './count'
import { MixedVars, StringVars } from './interpolate'
import { string } from './string'

type CountableKey = {
  count: number
  singularKey: LocalizationKey
  pluralKey: LocalizationKey
}

function getKey({
  count,
  singularKey,
  pluralKey,
}: CountableKey): LocalizationKey {
  return count === 1 ? singularKey : pluralKey
}

export function dual(
  count: number,
  singularKey: LocalizationKey,
  pluralKey: LocalizationKey,
  variables: Record<string, MixedVars> = {}
): JSX.Element {
  const smart_count = countFn(count)

  // eslint-disable-next-line local/no-variable-l10n-key
  return __(getKey({ count, singularKey, pluralKey }), {
    ...variables,
    smart_count,
  })
}

dual.string = function dualString(
  count: number,
  singularKey: LocalizationKey,
  pluralKey: LocalizationKey,
  variables: Record<string, StringVars> = {}
): string {
  const smart_count = countFn(count)

  return string(getKey({ count, singularKey, pluralKey }), {
    ...variables,
    smart_count,
  })
}
