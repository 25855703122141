import 'urlpattern-polyfill'
import 'intersection-observer'

import '../../src/css/index.webpack.styl'

// eslint-disable-next-line levitate/import-name-after-file-name
import localizationFunction from '../tw-localization/__'

declare global {
  interface Window {
    // Disallow accessing `window.$` due to poor traceability
    $: never
  }
}

window.global = window.global || window
declare global {
  const __: typeof localizationFunction
  interface Window {
    __: typeof localizationFunction
  }
}

window.__ = localizationFunction

// Define the type of `import.meta.env.TW_FRONTEND_COMMIT_SHA`
declare global {
  interface ImportMetaEnv {
    readonly TW_FRONTEND_COMMIT_SHA: string
  }
}
